import React, { useContext, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { homePagePaths } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import { LanguageContext } from "../context"
import Button from "../components/button-generic"
import Filters from "../components/search/filters"
import AuthorCard from "../utils/blog/author-card"
import SharePostSocialMedia from "../utils/social-media/share"
import SimilarItems from "../components/blog/similar-items"
import DrupalComments from '../../plugins/gatsby-plugin-drupal-comments-unida/src'
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Seo from "../components/seo"
import { debounce } from 'lodash'
import ReactPlayer from 'react-player';

const prefixAliasWithLanguage = (alias, langcode = 'ca') => (
    langcode === 'ca'
    ? `/author/${alias}`
    : `/${langcode}/author/${alias}`
  )


const PageTestimony = ({
    data,
    location,
    pageContext,
    categories,
    refreshList,
    initialQuery
  }) => {
    console.log("DATA", data);
    const post = data?.nodeTestimony
    const { t } = useContext(LanguageContext)
    const fullUrl = typeof window !== 'undefined' ? window.location.href : ''
    const breakpoints = useBreakpoint()
    const contentId = post?.drupal_internal__nid

    const sendViewCount = debounce(() => {
      const username = process.env.GATSBY_BASIC_AUTH_USERNAME
      const password = process.env.GATSBY_BASIC_AUTH_PASSWORD
      const encodedCredentials = btoa(`${username}:${password}`);
      if (typeof window !== "undefined") {
        fetch(`${process.env.GATSBY_DRUPAL_API_URL}/d-stats/record-view`, {
          method: 'POST',
          headers: {
              'Authorization': `Basic ${encodedCredentials}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ content_id: contentId }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
        })
        .catch((error) => {
            //console.error('Error:', error);
        });
      }

    }, 5000); // Debounce for 5 seconds


  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
        sendViewCount();
    }else{
      console.log("We are in dev....", contentId)
    }
    // Cleanup function
    return () => sendViewCount.cancel();
  }, [contentId, sendViewCount]);

 


    const getCategory = () => {
      let cats = []
      cats = post?.relationships?.field_category.map((cat) => {
        return {'name': cat.name, 'path': cat.path.alias, 'type': 'cat'}
      })
      let tags = []
      tags = post?.relationships?.field_tag.map((tag) => {
        return {'name': tag.name, 'path': tag.path.alias, 'type': 'tag'}
      })
      const catsTags = [...new Set([...cats, ...tags])];
      return catsTags?.map(item => (
        <React.Fragment key={item.name}>
          <Link to={item.path} className={item.type}>{item.name}</Link>
          {/*<span className={item.type}>{item.name}</span>*/}
          {catsTags.indexOf(item) !== catsTags.length - 1 ? ', ' : ''}
        </React.Fragment>
      ));
    }

    const getFullName = (post) => {
      if(post?.author?.field_name && post?.author?.field_surname){
        return `${post.author.field_name} ${post.author.field_surname}`
      }
      if(post?.author?.field_name){
        return post?.author.field_name
      }
      return 'Comunicació UNIDA'
    }

    const getAuthorUrl = (post) => {
      if(post?.author?.field_name && post?.author?.field_surname){
        return `${post.author.field_name} ${post.author.field_surname}`
      }
      if(post?.author?.field_name){
        return post?.author.field_name
      }
      return 'Comunicació UNIDA'
    }

    // Function to render the media section
    const renderMediaSection = () => {
      const videoUrl = post?.relationships?.field_testimony_video?.field_media_oembed_video;
      const imageUrl = post?.relationships?.field_testimoni_image?.relationships?.field_media_image?.image_style_uri?._653_432;
    
      if (videoUrl) {
        // Using a wrapper div to maintain aspect ratio
        return (
          <div className="video-wrapper" style={{ position: 'relative', paddingTop: '56.25%' /* 16:9 Aspect Ratio */ }}>
            <ReactPlayer
              url={videoUrl}
              width="100%"
              height="100%"
              controls={false}
              style={{ position: 'absolute', top: '0', left: '0' }}
              config={{
                youtube: {
                  playerVars: { showinfo: 0 } // Just an example, this specific parameter is deprecated
                }
              }}
            />
          </div>
        );
      } else if (imageUrl) {
        // If there is an image URL but no video, render the image
        return (
          <img
            src={imageUrl}
            alt={post?.title}
            style={{ width: '100%', height: 'auto', display: 'block', margin: '0 auto' }}
          />
        );
      } else {
        // If there is neither video nor image URL, render a default placeholder
        return <div className="image-placeholder">No media available</div>;
      }
    };
    
    

    return (
          <Layout
            translationPaths={homePagePaths}
            location={location}
            langcode={post?.langcode}
          >
          <Seo
              title={post?.title}
              lang={post?.langcode}
              description={post?.body?.summary}
              image={post?.relationships?.field_testimoni_image?.relationships?.field_media_image?.image_style_uri?._653_432}
            />
            <Header
              lang={pageContext?.langcode}
              location={location}
            />
            <div className="page-blog-post page-testimony">
            <section className="m-t-64 m-b-32 title-section small-m-t-40 small-m-b-16 ">
              <div className="container">
                <div className="row small-0">
                  <div className="col-12 col-md-10 offset-md-1 p-0 title-wrapper">
                    <h1 className={`bordeaux ${breakpoints.sm ? 'justify-content-center' : 'justify-content-left'} d-flex p-t-36 m-b-0`}>
                      {post?.title}
                    </h1>
                 </div>
                </div>
              </div>
              </section>
              <section className="interior-content content-section">
               <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-6 offset-md-1 p-0- m-r-20 p-l-0 p-r-10 medium-0">
                  <div className={`col-10- img-interior-ministry p-0 m-b-24 ${breakpoints.sm_md ? 'd-flex justify-content-center' : ''}`}>
                    {renderMediaSection()}
                  </div>
                    <div
                      dangerouslySetInnerHTML={{__html: post?.body?.processed}}
                      className="simple-page p-b-80"
                    />
                    <div className="comments m-b-80">
                      {/*
                    <DrupalComments
                         entity_id = {post?.drupal_id}
                         type_comment = "comment"
                         field_name = "field_comment"
                         type_content = "testimony"
                         entity_type = "node"
                         url_api = {`${process.env.GATSBY_DRUPAL_API_URL}/jsonapi/comment/comment`}
                         login_api = {`${process.env.GATSBY_BASIC_AUTH_USERNAME}`}
                         password_api = {`${process.env.GATSBY_BASIC_AUTH_PASSWORD}`}
                         fields_names ={[
                           {'name': 'Nom'},
                           {'name': 'E-mail'},
                           {'name': 'Tema'},//["data-params"]["data-fields_names"][0].name
                           {'name': 'Missatge'},
                           {'name': 'Enviar'}
                         ]}
                      />
                      */}
                    </div>
                  </div>
                  <div className="col-12 col-md-3 offset-md-1 sidebar p-0 medium-100">
                  <div className="box-1 post-author">
                    {/*
                    <AuthorCard
                      name={getFullName(post)}
                      photo={post?.author?.relationships?.field_image?.image_style_uri?.medium}
                      date={post?.created}
                      tagsCategories={getCategory()}
                      className="author-card background-beig m-b-24"
                      tagline={post?.author?.field_tagline}
                      authorUrl={prefixAliasWithLanguage(post?.author.name,post?.langcode)}
                    />*/}

                  </div>
                  <div className="box-2 share-social-media m-b-66">
                    <SharePostSocialMedia
                      imageUrl={post?.relationships?.field_image?.image_style_uri?._653_432}
                      className="share-this"
                      url={fullUrl}
                      title={post?.title}
                      socialmedia={[
                        'twitter',
                        'facebook',
                        'linkedin',
                        'pinterest'
                      ]}
                      description={post?.body?.summary}
                      boxTitle="Compartir testimoni"
                    />
                  </div>
                  <div className="box-3 related-posts small-m-b-64 medium-m-b-40">
                    <div className="box-title Small-Bold-L---Bordeaux m-b-18">
                      {"testimonis recomanats"}
                    </div>
                    {
                    <SimilarItems
                      category={post?.relationships?.field_campaign}
                      currentItemSlug={post?.path?.alias}
                      lang={post?.langcode}
                     />
                     }
                  </div>
                  </div>
                </div>
              </div>
              </section>
              </div>
            <Footer
              lang={pageContext?.langcode}
              location={location}
             />
          </Layout>
    )
}



export const pageQuery = graphql`
query PageTestimony ($drupal_internal__nid: Int, $langcode: String!) {
    nodeTestimony(
        drupal_internal__nid: {eq: $drupal_internal__nid},
        langcode:{eq: $langcode}
        ){
      drupal_id
      drupal_internal__nid
      title
      body{
            summary
            processed
      }
      created(locale: $langcode, formatString: "D MMMM Y")
      langcode
      path{
        alias
      }
      relationships {
        field_campaign {
          name
          path {
            alias
          }
        }
        field_testimony_video {
          field_media_oembed_video
          name
        }
        field_testimoni_image {
          field_media_image {
            alt
            title
            width
            height
            drupal_internal__target_id
          }
          name
          relationships {
            field_media_image {
              image_style_uri {
                _1103x488
                _120x120
                _1440x408
                _1440x960
                _294x192
                _314x208
                _541x359
                _653_432
                large
                medium
                sponsors
                thumbnail
                wide
              }
            }
          }
        }
      }
    sticky
    }
 }
`

export default PageTestimony
